.categoryBar {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 18px;
  /* Text */
  font-weight: 700;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 0.7034;
  color: rgba(38, 50, 56, 1);
  background-color: rgba(243, 243, 243, 1);
}

.changeLabel {
  font-family: Roboto-Bold, sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  color: #263238;
}

.changeValue {
  font-family: Roboto-Regular, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  color: #546e7a;
}

.description {
  font-family: Roboto-Regular, sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  opacity: 0.6;
  color: rgba(84, 110, 122, 1);
}

.profilesTable {
  overflow: auto;
  max-height: 250px;
}

.blurredBackdrop {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(2px);
}

.backdropContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2), 0 25px 50px 0 rgba(0, 0, 0, .1);
  gap: 20px;
}