/* !important is used when CoreUI doesn't want to play nice */

.smallTitle {
  font-size: 16px;
  color: #5b5b5b;
  display: flex;
  justify-content: center;
  margin-top: 7px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  color: #212121;
  display: flex;
  justify-content: center;
}

.submitButton {
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  text-transform: none;
  background-color: #6e00ff;
  font-size: 18px;
  font-weight: bold !important;
}

.clearButton {
  text-transform: none;
  font-size: 18px;
  font-weight: bold !important;
}

.uploadFileCard {
  background-color: #f4f2f5 !important;
  padding: 2rem;
  border-radius: 5px;
  border: none !important;
  height: 100% !important;
}

.clearFieldButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 40px;
  right: 10px;
  transition: opacity .2s ease-in-out;
  display: flex;
  align-items: center
}

.clearFieldButton:hover {
  opacity: .65;
}

.clearFieldButton:disabled {
  opacity: 0 !important;
  pointer-events: none;
}

.noHeight {
  height: 0;
  overflow: hidden;
}