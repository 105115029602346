.closeButton {
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

.header {
  font-family: Roboto-Bold, sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #263238;
  gap: 10px;
}

.description {
  font-family: Roboto-Regular, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  color: #546e7a;
}

.cardTitle {
  font-family: Roboto-Bold, sans-serif;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  color: #263238;
}

.undoIconGray {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.undoButton {
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

.undoButton img {
  width: 26px;
  height: 26px;
  margin: 0 5px;
}

.undoButton:disabled {
  opacity: 0;
  cursor: default;
}