.header-label {
  cursor: pointer;
}

.header-label:hover {
  opacity: 0.75;
}

.header-label:hover .hover-icon {
  opacity: 0.75;
}

.hover-icon {
  opacity: 0;
}

.hover-icon:hover {
  opacity: 0.75;
}
