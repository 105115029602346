.title {
  color: rgba(38, 50, 56, 1);
  font-family: "Roboto-Bold", sans-serif;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
}

.text-light {
  opacity: 1;
  color: rgba(84, 110, 122, 1);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
}

.category-bar {
  background-color: rgba(243, 243, 243, 1);
  opacity: 0.7034;
  /* Text */
  color: rgba(38, 50, 56, 1);
  font-family: "Roboto-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
}
