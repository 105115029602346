.row {
  background-color: white;
}

.selectedRow {
  background-color: #eeeaf8;
}

.idCell {
  font-size: .8rem;
}