.textSecondary {
  color: #546e7a;
  font-family: "Helvetica", sans-serif;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.invalidOption {
  display: none !important;
}