.assignmentImageDiv {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 100%;
  display: block;
}

.ownershipBadge {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  margin-bottom: 12px;
  font-family: Roboto-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  border-radius: 10px;
}

.ownerBadge {
  color: #6e02ff;
  background-color: rgba(110, 2, 255, .1);
}

.sharedBadge {
  color: #546e7a;
  background-color: rgba(84, 110, 122, .1);
}