.gridProfile {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 385px;
  padding: 15px;
  transition: outline-color .2s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  border-radius: 12px;
  outline: 2px solid transparent;
  background-color: #fff;
  gap: 16px;
}

.gridProfileSelected {
  transition: outline-color .2s ease-in-out;
  outline: 2px solid rgba(110, 2, 255, 1);
}

.checkboxRow {
  font: inherit;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: none;
  outline: none;
  background: none;
}

/* Maybe I'm overusing !important, but CoreUI really isn't helping... */
.avatar {
  width: 78px !important;
  min-width: 78px !important;
  height: 78px !important;
  min-height: 78px !important;
  background-color: red !important;
  border: 4px solid #f0e5ff;
}

.avatarInitials {
  font-size: 28px;
}

.name {
  font-family: Roboto-Bold, sans-serif;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  opacity: 1;
  color: #263238;
}

.email {
  font-family: Roboto-Regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, .6);
}

.phoneNumber {
  font-family: Roboto-Bold, sans-serif;
  font-size: 11px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, .7);
}

.badges {
  display: flex;
  gap: 5px;
}

.subscriptionBadge {
  font-family: Roboto-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  border-radius: 10px;
}

.inactiveBadge {
  color: #ba0000;
  background-color: rgba(255, 0, 0, .1);
}

.expiredBadge {
  color: #ba0000;
  background-color: rgba(255, 0, 0, .1);
}

.proBadge {
  color: #6e02ff;
  background-color: rgba(110, 2, 255, .1);
}

.proLiteBadge {
  color: #fb8f02;
  background-color: rgba(251, 143, 2, .1);
}

.freeTrialBadge {
  color: #546e7a;
  background-color: rgba(84, 110, 122, .1);
}

.noneBadge {
  color: #263238;
  background-color: rgba(38, 50, 56, .03);
}

.fieldLabel {
  font-family: Roboto-Regular, sans-serif;
  font-size: 10px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0;
  text-transform: uppercase;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, .5);
}

.fieldValue {
  font-family: Roboto-Regular, sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
  color: #263238;
}

.numAssignments {
  font-family: Roboto-Bold, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  color: #263238;
}

.horizontalSeparator {
  height: 1px;
  opacity: 1;
  border: 1px solid rgba(235, 235, 235, 1);
  background-color: rgba(38, 50, 56, .1);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.footer > div {
  display: flex;
  align-items: center;
  gap: 5px;
}