.avatar {
  width: 32px;
  height: 32px;
  border: 2px solid #f0e5ff;
}

.info {
  font-family: Roboto-Bold, sans-serif;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-width: 200px;
  white-space: nowrap;
  letter-spacing: 0;
  text-overflow: ellipsis;
}

.infoName {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, 1);
}

.infoEmail {
  font-family: Roboto-Regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, .6);
}

.infoPhone {
  font-family: Roboto-Bold, sans-serif;
  font-size: 11px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(38, 50, 56, .7);
}