.toggle {
  display: flex;
  padding: 5px;
  opacity: 1;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
  gap: 5px;
}

.option {
  padding: 0;
  cursor: pointer;
  transition: background-color .2s ease;
  border: none;
  border-radius: 6px;
  outline: none;
  background-color: transparent;
}

.optionSelected {
  transition: background-color .2s ease;
  border-radius: 6px;
  background-color: rgba(110, 2, 255, .1);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}