.table {
  font-size: 14px;
}

.header {
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: #546e7a;
  background-color: #f4f6f8;
}

.headerActive {
  color: white;
  background-color: #9649fc;
}

.headerCellButton {
  font: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  text-align: left;
  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

.headerCellButton svg {
  transition: opacity .1s;
  opacity: 0;
}

.headerCellButton:hover svg {
  transition: opacity .1s;
  opacity: .5;
}

.headerCellButtonActiveSort svg {
  opacity: 1;
}

.headerCellButtonActiveSort:hover svg {
  opacity: 1;
}

.footer {
  background-color: #f4f6f8;
}

.invisibleText {
  user-select: none;
  color: transparent;
}

.invisibleButton {
  cursor: default; /* CoreUI doesn't respect this ¯\_(ツ)_/¯ */
  user-select: none;
  opacity: 0;
  color: transparent;
  background-color: transparent;
}

.visibleOnHoverArrow {
  transition: opacity .1s;
  opacity: 0;
}

.visibleOnHoverArrow:hover {
  transition: opacity .1s;
  opacity: .5;
}

.filterSelect {
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  flex-basis: 100%;
  width: auto;
  /*max-width: 270px;*/
  text-align: left;
  letter-spacing: 0;
  color: #546e7a;
  border-radius: 10px;
  --cui-form-select-bg: #e5e8eb;
}

.actionsButton {
  font: inherit;
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  color: #546e7a;
  border: none;
  outline: inherit;
  background: none;
  gap: 10px;
}

.actionRed {
  color: #cb5439;
}

.cursorDefault {
  cursor: default !important;
}

.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.searchBar {
  flex-basis: 100%;
}

@media (min-width: 576px) {
  .searchBar {
    flex-basis: 243px;
  }

  .filterSelect {
    flex-basis: auto;
    max-width: 270px;
  }
}